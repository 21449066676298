import React from 'react';

import Layout from '../components/Layout';



const IndexPage = () => {
  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.


  return (<Layout activeLink="contact" noFooter={true}>
           <div className="background--gradient">
			<div className="wrapper">
				<div className="container">
					<div className="box--x">
						<div className="row">
							<div className="col-md-20 col-md-offset-2 col-sm-22 col-sm-offset-1">
								<ul className="list-icons clearfix">
									<li className="list-icons__item">
										<img className="list-icons__item-icon" src="https://res.cloudinary.com/dfvhyk2ut/image/upload/f_auto,q_auto,w_160,h_160/v1592248040/lindan-moments/icons/icon-email.svg" alt="email" aria-hidden="true"/>
										<h2 className="heading--tertiary">Email us</h2>
										<a className="link--l list-icons__item-text list-icons__item-email-link" href="mailto:enquires@lindanclassicmoments.co.uk?subject=Enquiry - Lindan Classic Moments" title="Send an email to Lindan Classic Moments">enquires@lindanclassicmoments.com</a>
										<small className="list-icons__item-text--small">We'll try our best to respond to an email within 1 working day.</small>
									</li>
									<li className="list-icons__item">
										<img className="list-icons__item-icon" src="https://res.cloudinary.com/dfvhyk2ut/image/upload/f_auto,q_auto,w_160,h_160/v1592248040/lindan-moments/icons/icon-phone.svg" alt="phone" aria-hidden="true"/>
										<h2 className="heading--tertiary">Call us</h2>
										<a className="number-active" href="tel:00447958376608"><span className="tap-to-call">07958376608</span></a>
									</li>

									<li className="list-icons__item">
										<h2 className="heading--tertiary">Social media</h2>
										<a className="list-icons__item-social-link" href="https://www.facebook.com/pages/category/Product-Service/Lindan-Classic-Moments-181417112583915/" target="_blank"  rel="noreferrer">
											<span className="visually-hidden">Find us on Facebook</span>
											<img className="list-icons__item-icon" src="https://res.cloudinary.com/dfvhyk2ut/image/upload/f_auto,q_auto,w_160,h_160/v1592250783/lindan-moments/icons/icon-facebook.svg" alt="Facebook" aria-hidden="true"/>
										</a>

										<a className="list-icons__item-social-link" href="https://www.instagram.com/lindanclassicmoments/" target="_blank"  rel="noreferrer">
											<span className="visually-hidden">Visit our Instagram account: @lindanclassicmoments</span>
											<img className="list-icons__item-icon" src="https://res.cloudinary.com/dfvhyk2ut/image/upload/f_auto,q_auto,w_160,h_160/v1592250783/lindan-moments/icons/icon-instagram.svg" alt="Instagram" aria-hidden="true"/>
										</a>
									</li>
								</ul> 
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  </Layout>
  );
};

export default IndexPage;